import React from "react"

import SEO from "../components/seo"
import Layout from "../shared/layout"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Der Pfad existiert nicht.</p>
  </Layout>
)

export default NotFoundPage
